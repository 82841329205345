
































































































































import vueDebounce from "vue-debounce";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
Vue.use(vueDebounce, {
  listenTo: ["input"]
});
import { core, usAutocomplete, usStreet } from "smartystreets-javascript-sdk";
import states from "@/assets/states";
import * as Type from "@/types/smarty-streets";
import {
  VCol,
  VRow,
  VTextField,
  VSelect,
  VIcon,
  VMenu,
  VList,
  VListItem,
  VListItemTitle
} from "vuetify/lib";

@Component({
  components: {
    VCol,
    VRow,
    VTextField,
    VSelect,
    VIcon,
    VMenu,
    VList,
    VListItem,
    VListItemTitle
  }
})
export default class SmartyStreets extends Vue {
  @Prop({ required: true }) websiteKey!: string;
  @Prop({ required: true }) smartyStreetsConfig!: Record<string, any>;
  @Prop({ required: false, default: true }) uppercase!: boolean;
  @Prop({ required: false, default: false }) isRequired!: boolean;
  @Prop({ required: false, default: () => ({}) }) counties!: Type.Counties;
  @Prop({ required: false, default: false }) showVerifyErrors!: boolean;
  @Prop({ required: false, default: true }) showStreet2!: boolean;
  @Prop({ required: false, default: true }) showCounties!: boolean;
  @Prop({ required: false, default: false }) verifyCounty!: boolean;
  @Prop({
    required: false
  })
  vuetifyProps!: {};
  @Prop({ required: true }) street!: string;
  @Watch("street") streetChanged() {
    Object.assign(this.address, {
      street: this.uppercase ? this.street?.toUpperCase() : this.street
    });
  }
  @Prop({ required: false, default: "" }) street2!: string;
  @Watch("street2") street2Changed() {
    Object.assign(this.address, {
      street2: this.uppercase ? this.street2?.toUpperCase() : this.street2
    });
  }
  @Prop({ required: true }) city!: string;
  @Watch("city") cityChanged() {
    Object.assign(this.address, {
      city: this.uppercase ? this.city?.toUpperCase() : this.city
    });
  }
  @Prop({ required: true }) state!: string;
  @Watch("state") stateChanged() {
    Object.assign(this.address, { state: this.state?.toUpperCase() });
  }
  @Prop({ required: true, default: "" }) zipCode!: string;
  @Watch("zipCode") zipChanged() {
    Object.assign(this.address, { zipCode: this.zipCode });
  }
  @Prop({ required: false, default: "" }) countyId!: string;
  @Watch("countyId") countyChanged() {
    Object.assign(this.address, { countyId: this.countyId });
  }
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: true }) useVerify!: boolean;

  private states = states();
  private lastSelectedAddress: Type.Address = {
    text: "",
    streetLine: "",
    city: "",
    state: ""
  };
  private menuIsOpen = false;
  private smartyStreetsCredentials: core.SharedCredentials;
  private lookup = {};
  private searchInput = "";
  private suggestions: Array<{}> = [];
  private candidates: usStreet.Candidate[] = [];
  private autocompleteClient: core.Client<
    usAutocomplete.Lookup,
    usAutocomplete.Lookup
  >;
  private streetClient: core.Client<
    usStreet.Lookup | core.Batch<usStreet.Lookup>,
    core.Batch<usStreet.Lookup>
  >;
  private address: Type.Address = {
    street: this.street,
    street2: this.street2,
    city: this.city,
    state: this.state,
    zipCode: this.zipCode,
    countyId: this.countyId
  };

  @Watch("address", { deep: true })
  addressChanged(address: Type.Address) {
    Object.keys(address).forEach((key: keyof Type.Address) => {
      if (address[key] != this[key as keyof SmartyStreets]) {
        if (this.uppercase && typeof address[key] === "string") {
          address[key] = address[key].toUpperCase();
        }
        this.$emit(key + "Input", address[key]);
      }
    });
  }

  get selectedAddress(): Type.Address {
    return this.lastSelectedAddress;
  }

  set selectedAddress(address: Type.Address) {
    Object.assign(this.lastSelectedAddress, address);
    this.address.zipCode = "";
    this.setAddressNonCandidate(this.lastSelectedAddress);
  }

  private handleTab(e: Event): void {
    if (this.menuIsOpen && this.suggestions.length !== 0) {
      e.preventDefault();
      const evt = new CustomEvent("keydown") as any;
      evt.which = 13;
      evt.keyCode = 13;
      e.target?.dispatchEvent(evt);
    }
  }

  private filter(): void {
    if (
      (typeof this.address.street === "string" &&
        this.address.street.length < 5) ||
      this.readonly
    ) {
      return;
    }

    const lookup = new usAutocomplete.Lookup(this.address.street);
    Object.assign(lookup, this.lookup);

    this.autocompleteClient
      .send(lookup)
      .then((lookup: usAutocomplete.Lookup) => {
        this.suggestions = lookup.result;
      })
      .catch(console.error);
  }

  private setAddress(address: usStreet.Candidate): void {
    //address is from us-street-api
    this.address.street = address.deliveryLine1;
    this.address.city = address.components.cityName;
    this.address.state = address.components.state;
    this.address.zipCode = address.components.zipCode;

    //set countyId
    if (this.verifyCounty === true) {
      const countyIds = Object.keys(this.counties);
      this.address.countyId = "";

      for (let i = 0; i < countyIds.length; i++) {
        if (this.counties[countyIds[i]] === address.metadata.countyName) {
          this.address.countyId = countyIds[i];
          return;
        }
      }
    }
  }

  private setAddressNonCandidate(address: Type.Candidate): void {
    //address is from us-autocomplete-api
    this.address.street = address.streetLine;
    this.address.city = address.city;
    this.address.state = address.state;
    this.$nextTick(() => {
      this.verify(true);
    });
  }

  private hasEnoughInfo(): boolean {
    try {
      return (
        this.address.street.length > 0 &&
        this.address.city.length > 0 &&
        this.address.state !== ""
      ); //true if enough info
    } catch (error) {
      return false; //If above properties are null, default to false
    }
  }

  private verify(autocomplete = false): void {
    if (!this.useVerify)
      //If this is set we dont want it to actually verify
      return;

    if (this.verificationStatus >= 0)
      //If last received candidates contain this address (despite county)
      return;

    if (!this.hasEnoughInfo())
      //If we don't have enough information to verify
      return;

    if (!this.streetClient) return;

    this.getCandidates().then(batch => {
      if (batch.lookups[0].result.length > 0) {
        this.candidates = batch.lookups[0].result;

        if (autocomplete === true && batch.lookups[0].result.length === 1) {
          this.setAddress(batch.lookups[0].result[0]);
          return;
        }

        this.suggestions = [];
      }
    });
  }

  private getCandidates() {
    const lookup = new usStreet.Lookup();

    lookup.street = this.address.street;
    lookup.city = this.address.city;
    lookup.state = this.address.state;
    lookup.zipCode = this.address.zipCode;

    return this.streetClient.send(lookup);
  }

  private preventInvalidZip(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, dash(-), enter and .
    if (
      ["Backspace", "Delete", "Tab", "Escape", "Enter", ".", "-"].includes(
        event.key
      ) ||
      // Allow: Ctrl+A, Command+A
      (event.key === "a" &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      [
        "End",
        "Home",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown"
      ].includes(event.key)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(event.key)
    ) {
      event.preventDefault();
    }
  }

  private convertForVSelect(
    keyedArray: Type.Counties,
    reverseKeys = false,
    changeKeysToInt = true
  ): Record<string, any> {
    if (changeKeysToInt) {
      if (reverseKeys)
        return Object.keys(keyedArray).map(key => {
          return {
            text: key,
            value: this.parseIntStrict(keyedArray[key]) || keyedArray[key]
          };
        });
      else
        return Object.keys(keyedArray).map(key => {
          return {
            value: this.parseIntStrict(key) || key,
            text: keyedArray[key]
          };
        });
    } else {
      if (reverseKeys)
        return Object.keys(keyedArray).map(key => {
          return { text: key, value: keyedArray[key] };
        });
      else
        return Object.keys(keyedArray).map(key => {
          return { value: key, text: keyedArray[key] };
        });
    }
  }

  private parseIntStrict(str: string): number {
    if (str.match(/^-{0,1}\d+$/)) {
      return parseInt(str);
    } else {
      return NaN;
    }
  }

  private isSimilar(str1: string, str2: string): boolean {
    if (typeof str1 === "string" && typeof str2 === "string") {
      return str1.toUpperCase() === str2.toUpperCase();
    } else {
      return false;
    }
  }

  get verificationStatus(): number {
    /**
     * The verification status based on us-street-api
     * -1:  invalid
     * 0:   valid
     * 1:   everthing but county is valid
     * @returns {number}
     */

    for (let i = 0; i < this.candidates.length; i++) {
      if (
        this.isSimilar(this.address.street, this.candidates[i].deliveryLine1) &&
        this.isSimilar(
          this.address.city,
          this.candidates[i].components.cityName
        ) &&
        this.isSimilar(
          this.address.state,
          this.candidates[i].components.state
        ) &&
        this.address.zipCode === this.candidates[i].components.zipCode
      ) {
        if (
          (Object.keys(this.counties).length === 0 &&
            this.counties.constructor === Object) ||
          (this.counties[this.address.countyId] !== undefined &&
            this.isSimilar(
              this.counties[this.address.countyId],
              this.candidates[i].metadata.countyName
            ))
        ) {
          return 0;
        } else {
          return 1;
        }
      }
    }
    return -1;
  }

  async created() {
    this.smartyStreetsCredentials = new core.SharedCredentials(this.websiteKey);
    this.autocompleteClient = core.buildClient.usAutocomplete(
      this.smartyStreetsCredentials
    );
    this.streetClient = core.buildClient.usStreet(
      this.smartyStreetsCredentials
    );
  }

  async mounted() {
    await this.$nextTick(async () => {
      if (this.uppercase) {
        Object.keys(this.address).forEach(key => {
          if (typeof this.address[key] === "string")
            this.address[key] = this.address[key].toUpperCase();
        });
      }
      if (!this.readonly) {
        this.verify();
      }
      this.lookup = this.smartyStreetsConfig;
    });
  }
}
